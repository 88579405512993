var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-flow","title":'ข้อมูลเลขที่ตำแหน่งว่าง รายงานตัวเลือกสถานศึกษา ตำแหน่ง รองผู้อำนวยการ รอบที่ : ' +
          _vm.period_sedirectors.period_sedirector_times +
          ' / ' +
          _vm.period_sedirectors.period_sedirector_year}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headers,"items":_vm.director_college_manpowers,"search":_vm.search,"item-class":_vm.row_classes,"disable-pagination":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
          var items = ref.items;
          var isOpen = ref.isOpen;
          var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"10"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_vm._v(" "+_vm._s(items[0].region_name)+" ")],1)]}},{key:"item.whoBooking",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.whoBooking))])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }