<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-flow"
        :title="
          'ข้อมูลเลขที่ตำแหน่งว่าง รายงานตัวเลือกสถานศึกษา ตำแหน่ง รองผู้อำนวยการ รอบที่ : ' +
            period_sedirectors.period_sedirector_times +
            ' / ' +
            period_sedirectors.period_sedirector_year
        "
        class="px-5 py-3"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        /><!--  group-by="region_name" -->
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="director_college_manpowers"
          :search="search"
         
          class="elevation-1"
          :item-class="row_classes"
          disable-pagination
         
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="10">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].region_name }}
            </th>
          </template>

          <template v-slot:item.whoBooking="{ item }">
            <span>{{ item.whoBooking }}</span>
          </template>

                 <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>   
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HRvecManageAssistantTeacher",
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
  
      period_sedirectors:[],
      director_college_manpowers: [],    
      search: "",
      pagination: {},
      headers: [
        { text: "รอบที่", align: "center", value: "dcm_times" },
        { text: "ปี", align: "center", value: "dcm_years" },
        { text: "ภาค", align: "center", value: "region_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่ง", align: "left", value: "dcm_idpositons" },
        { text: "สถานะ", align: "left", value: "whoBooking" },      
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
     
    };
  },
  async mounted() {
    await this.showLocaleTime();
    await this.period_sedirectorQuery();  
    if (this.period_sedirectors.period_sedirector_enable != "1")
    {
      Swal.fire({
          icon: "error",
          title: "ยังไม่มีกำหนดการ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/");
          }
        });
    }else{
      await this.director_college_manpowerQueryAll();
    }

    
  },
  methods: {  
    async showLocaleTime() { 
      setInterval(async() => {
        await this.director_college_manpowerQueryAll();
      }, 3000);     
    },

    async period_sedirectorQuery() {
      let result = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_id: "206",
        period_sedirector_process: "1"
      });
      this.period_sedirectors = result.data;
    },

  
    async director_college_manpowerQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("director_college_manpower.php", {
          ApiKey: this.ApiKey,
          dcm_times: this.period_sedirectors.period_sedirector_times,
          dcm_years: this.period_sedirectors.period_sedirector_year
        })
        .finally(() => (this.loading = false));
      this.director_college_manpowers = result.data;
    },

    
    row_classes(item) {
      if (item.dcm_id_card_booking) {
        return "green";
      }
    }
  },
  computed: {
    color() {
      return "light-blue-darken-4";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>

<style lang="scss" scoped></style>
